import { Box, colors, Flex, Text } from "@biblioteksentralen/react";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { ResolvedRecommendation, ResolvedRecommendationSummary } from "../../../recommendation/sanityQuery";
import CoverImageWithPlaceholder from "../../../lists/CoverImageWithPlaceholder";
import { getPath } from "../../../../utils/getPath";
import TargetAudiences from "../../../lists/TargetAudiences";
import { Teaser } from "../../../recommendation/Teaser";
import { getContainerQuery } from "./utils";
import { SingleItemBanner } from "../SingleItemBanner";
import { ResolvedPaletteColor } from "../../../../utils/useSitePalette";

export const FeaturedRecommendationBanner = ({
  recommendation,
  bannerColor,
}: {
  recommendation: ResolvedRecommendationSummary | ResolvedRecommendation;
  bannerColor?: ResolvedPaletteColor;
}) => {
  const { t } = useTranslation();
  const [title] = recommendation.publication?.title?.split(":") ?? [];

  const image = recommendation.publication && (
    <Flex
      justifyContent="center"
      placeItems="center"
      height="100%"
      sx={getContainerQuery({ base: { padding: "2rem" }, sm: { padding: "0" } })}
    >
      <Box sx={getContainerQuery({ base: { display: "block" }, sm: { display: "none" } })}>
        <CoverImageWithPlaceholder publication={recommendation.publication} imageWidth="7rem" minWidth="3rem" />
      </Box>
      <Box sx={getContainerQuery({ base: { display: "none" }, sm: { display: "block" } })}>
        <CoverImageWithPlaceholder publication={recommendation.publication} imageWidth="10rem" minWidth="3rem" />
      </Box>
    </Flex>
  );

  return (
    <SingleItemBanner
      aria-description={t("anbefaling")}
      as="aside"
      seeMore={{ href: getPath(recommendation), label: t("Se anbefalingen") }}
      title={title}
      tagline={<TargetAudiences targetAudiences={recommendation.targetAudiences} />}
      customImageComponent={image}
      editDocumentProps={recommendation}
      bannerColor={bannerColor}
      extraInfo={
        <>
          <Text fontSize=".8rem">
            {recommendation.publication?.author} {"•"} {recommendation.publication?.publicationYear}
          </Text>
          <Teaser
            gridColumn="2/3"
            gridRow="2/3"
            alignSelf="center"
            padding=".5rem .75rem .5rem .25rem"
            fontSize="xs"
            noOfLines={4}
            recommendation={recommendation}
            quoteMarkProps={{
              color: bannerColor?.isBright ? colors.white : "rgba(255,255,255,.2)",
              marginRight: "-.25em",
            }}
            placeSelf="start"
          />
        </>
      }
    />
  );
};
