import { BoxProps } from "@biblioteksentralen/react";
import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { ResolvedPaletteColor } from "../../../utils/useSitePalette";
import SingleItemBanner from "../../site/banners/SingleItemBanner";
import CustomCampaignDate from "./CustomCampaignDate";
import { ResolvedCustomCampaignBannerData } from "./sanityQuery";

type CustomCampaignBannerProps = {
  customCampaign: ResolvedCustomCampaignBannerData;
  bannerColor: ResolvedPaletteColor;
} & BoxProps;

export const CustomCampaignBanner = ({ customCampaign, bannerColor, ...boxProps }: CustomCampaignBannerProps) => {
  const { t, ts } = useTranslation();

  return (
    <SingleItemBanner
      aria-description={t("kampanje")}
      as="aside"
      bannerColor={bannerColor}
      imageSide="right"
      sanityImageSrc={customCampaign.image}
      seeMore={{
        href: getPath(customCampaign),
        label: t("Les mer"),
        ariaLabel: t("Les mer om {tittel}", { tittel: ts(customCampaign.title) }),
      }}
      promotion={customCampaign.promotion}
      editDocumentProps={customCampaign}
      title={ts(customCampaign.title)}
      teaser={ts(customCampaign.teaser)}
      extraInfo={<CustomCampaignDate campaign={customCampaign} fontSize="sm" fontWeight="600" />}
      {...boxProps}
    />
  );
};
