import { colors } from "@biblioteksentralen/react";
import { FrontendLocale } from "@libry-content/localization";
import { getSelfServiceLabel } from "../../../utils/getSelfServiceLabel";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import useRerenderInterval from "../../../utils/useRerenderInterval";
import { useCommonData } from "../../layout/CommonDataProvider";
import { DateHelper } from "@libry-content/common";
import { Admittance } from "./getTimespan";
import { useNearestOpenNormalHoursThisWeek } from "./nearestOpenMoment";
import { OpeningHoursHelper } from "./openingHoursHelper";

export const STATUS_COLORS = {
  OPEN: colors.statusGreen,
  CLOSED: colors.statusRed,
  WARNING: colors.statusYellow,
};

interface Returns {
  statusText: string;
  nextNormalOpeningoursStatus?: string;
  statusColor: string;
  currentState: Admittance;
}

const namedMinuteIntervals = [60, 20, 10, 5] as const;

type NamedMinuteInterval = (typeof namedMinuteIntervals)[number];

const minuteIntervalDescriptions: Record<NamedMinuteInterval, Record<FrontendLocale, string>> = {
  60: { nb: "snart", nn: "snart" },
  20: { nb: "om under 20 min.", nn: "om under 20 min." },
  10: { nb: "om under 10 min.", nn: "om under 10 min." },
  5: { nb: "om under 5 min.", nn: "om under 5 min." },
};

/**
 * åpent nå, meråpent [om intervall] -> stenger [om intervall] <– special case
 * åpent nå, stenger [om intervall] -> stenger [om intervall]
 *
 * stengt nå, åpner [om intervall] -> åpner [om intervall]
 * stengt nå, meråpent [om intervall] -> meråpent [om intervall]
 *
 * meråpent nå, åpner [om intervall] -> meråpent nå, åpner [om intervall] <– special case
 * meråpent nå, stenger [om intervall] -> meråpent nå, stenger [om intervall] <– special case
 */
const getTransitionStatusText = (
  stateLabel: Record<Admittance, string>,
  nextStateAsVerb: Record<Admittance, string>,
  currentState: Admittance,
  nextState: Admittance,
  intervalDescription: string | undefined
): string => {
  const nextStateVerb = nextStateAsVerb[nextState];

  if (currentState === "normal" && nextState === "selfService") {
    return `${nextStateAsVerb.closed} ${intervalDescription}`;
  }

  if (currentState === "selfService") {
    const currentStateLabel = stateLabel[currentState];
    return `${currentStateLabel}, ${nextStateVerb} ${intervalDescription}`;
  }

  return `${nextStateVerb} ${intervalDescription}`;
};

export const nearestIntervalClampedUp = (target: number) =>
  [...namedMinuteIntervals].sort((left, right) => left - right).filter((current) => current >= target)[0];

export function useCurrentOpeningHoursStatus(helper: OpeningHoursHelper): Returns {
  useRerenderInterval(); // For at statusen skal holde seg oppdatert om burkeren er lenge på siden
  const { site } = useCommonData();
  const { t, lang } = useTranslation();
  const selfServiceLabel = getSelfServiceLabel(lang, site);
  const nextNormalOpeningoursText = useNearestOpenNormalHoursThisWeek(helper);

  // Middle of sentence; not capitalized
  const nextStateAsVerb: Record<Admittance, string> = {
    closed: t("stenger"),
    normal: t("åpner"),
    selfService: selfServiceLabel,
  };

  // Start of sentence; capitalized
  const stateLabel: Record<Admittance, string> = {
    closed: t("stengt nå"),
    normal: t("åpent nå"),
    selfService: `${selfServiceLabel} ${t("nå")}`,
  };

  const {
    hasSelfService,
    todaysOpeningHours: { normalHours, selfService },
    nextTransition: { happensAt, currentState, nextState },
  } = helper;

  if (normalHours?.closed && (!hasSelfService || !selfService?.enabled)) {
    return {
      statusText: t("stengt i dag"),
      statusColor: STATUS_COLORS.CLOSED,
      currentState,
      nextNormalOpeningoursStatus: nextNormalOpeningoursText,
    };
  }

  const dateHelper = DateHelper.fromTime(happensAt);

  // Handle transitional states
  if (dateHelper.minutesFromNow <= Math.max(...namedMinuteIntervals) && nextState !== currentState) {
    const intervalBetweenStates = nearestIntervalClampedUp(dateHelper.minutesFromNow);
    const intervalDescription = intervalBetweenStates
      ? minuteIntervalDescriptions[intervalBetweenStates][lang]
      : undefined;

    const statusText = getTransitionStatusText(
      stateLabel,
      nextStateAsVerb,
      currentState,
      nextState,
      intervalDescription
    );

    return {
      statusText,
      statusColor: STATUS_COLORS.WARNING,
      currentState,
      nextNormalOpeningoursStatus: nextState !== "normal" ? nextNormalOpeningoursText : undefined,
    };
  }

  if (currentState === "closed") {
    return {
      statusText: stateLabel[currentState],
      statusColor: STATUS_COLORS.CLOSED,
      currentState,
      nextNormalOpeningoursStatus: nextNormalOpeningoursText,
    };
  }

  // Show self service as warning color to indicate it's not regular opening hours
  const statusColor = currentState === "normal" ? STATUS_COLORS.OPEN : STATUS_COLORS.WARNING;

  return {
    statusText: stateLabel[currentState],
    statusColor,
    currentState,
    nextNormalOpeningoursStatus: currentState !== "normal" ? nextNormalOpeningoursText : undefined,
  };
}
