import { Box, colors, ContainerProps, Flex, Grid, GridProps, Text } from "@biblioteksentralen/react";
import { DateHelper, richTextHasContent, UTCDateToNorwegian } from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { Alert as AlertType } from "@libry-content/types";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import { ReactNode, useId } from "react";
import { AlertCircle } from "react-feather";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { DefaultContainer } from "../../../ContentContainer";
import BlockContent from "../../../blockContent/BlockContent";
import { Edit } from "../../../editInSanity/EditInSanity";

const BlockContentWrapper: typeof Box = (props) => (
  <Box
    sx={{
      "div >": {
        "*": {
          maxWidth: "100%",
        },
        "p:last-child": {
          marginBottom: 0,
        },
      },
    }}
    {...props}
  />
);

const getLastUpdatedTimeOrDate = (code: FrontendLocale, dateStr: string) => {
  if (!dateStr) return "";

  const lastUpdatedDate = UTCDateToNorwegian(new Date(dateStr));
  const time = format(lastUpdatedDate, "HH:mm");

  if (isToday(lastUpdatedDate)) return `i dag ${time}`;
  if (isYesterday(lastUpdatedDate)) return `i går ${time}`;
  return DateHelper.getLabel(code, dateStr);
};

type Props = {
  alert?: AlertType;
} & ContainerProps;

const AlertBanner = ({ alert, ...chakraProps }: Props) => {
  const { lang, ts } = useTranslation();
  if (!alert || (!ts(alert?.title)?.trim() && !richTextHasContent(ts(alert?.body)))) return null;

  return (
    <DefaultContainer withoutPaddingXBellow="md" {...chakraProps}>
      <Layout
        title={ts(alert.title)}
        date={getLastUpdatedTimeOrDate(lang, alert.updatedByEditorAt ?? alert._updatedAt)}
      >
        <BlockContentWrapper gridColumn="2/4" gridRow="2/3">
          <BlockContent fontSize="md" blocks={ts(alert.body)} logInternalLinks />
          <Box paddingTop=".5rem" gridColumn="2/3" gridRow="3/4">
            <Edit doc={alert} />
          </Box>
        </BlockContentWrapper>
      </Layout>
    </DefaultContainer>
  );
};

const alertColor = "#b05b01";

type LayoutProps = { children: ReactNode; title?: string; date?: string } & GridProps;

const Layout = ({ children, title, date, ...chakraProps }: LayoutProps) => {
  const headingId = useId();

  return (
    <Grid
      gridTemplateColumns={title ? "1.5rem 1fr 5rem" : "1.5rem 1fr"}
      borderRadius={{ base: "0", sm: ".6rem" }}
      padding="1rem"
      backgroundColor={colors.statusYellowLight}
      columnGap="0.5rem"
      role="alert"
      aria-labelledby={headingId}
      {...chakraProps}
    >
      <Box gridColumn="1/2" gridRow="1/2" color={alertColor} fontSize="1.2rem" marginTop="0.2rem">
        <AlertCircle />
      </Box>
      {title && (
        <Text as="h2" color={alertColor} fontWeight="semibold" gridColumn="2/3" gridRow="1/2" id={headingId}>
          {title}
        </Text>
      )}
      {children}
      {date && (
        <Flex
          color={alertColor}
          justifyContent="flex-end"
          alignSelf="flex-start"
          whiteSpace="nowrap"
          gridColumn="3/4"
          gridRow="1/3"
        >
          {date}
        </Flex>
      )}
    </Grid>
  );
};

export default AlertBanner;
