import { Heading, Stack } from "@biblioteksentralen/react";
import { useId } from "react";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { DefaultContainer } from "../../../ContentContainer";
import { EventsList } from "../../../arrangement/EventsList";
import { ResolvedEventSummary } from "@libry-content/common";
import { CreateNew } from "../../../editInSanity/EditInSanity";
import SeMerLenke from "../../SeMerLenke";

interface Props {
  events?: ResolvedEventSummary[];
}

const EventsBanner = ({ events }: Props) => {
  const headingId = useId();
  const { t } = useTranslation();

  if (!events?.length) return null;

  return (
    <DefaultContainer>
      <Stack as="section" aria-labelledby={headingId} spacing="2rem">
        <Heading as="h2" id={headingId}>
          {t("Dette skjer hos oss")}
        </Heading>
        <Stack spacing="1rem">
          <CreateNew type="event" />
          <EventsList events={events} />
          <SeMerLenke alignSelf="flex-end" href="/arrangementer">
            {t("Se alle arrangementer")}
          </SeMerLenke>
        </Stack>
      </Stack>
    </DefaultContainer>
  );
};

export default EventsBanner;
