import { Box, ChakraProps, Flex, Stack, Text } from "@biblioteksentralen/react";
import { Library } from "@libry-content/types";
import { capitalizeFirstLetter } from "../../utils/css/capitalizeFirstLetter";
import { useCommonData } from "../layout/CommonDataProvider";
import { OpeningHoursHelper } from "./openingHoursHelper/openingHoursHelper";
import { useCurrentOpeningHoursStatus } from "./openingHoursHelper/useCurrentOpeningHoursStatus";

type Props = { library: Library } & ChakraProps;

export function OpeningHoursStatus({ library, ...chakraProps }: Props) {
  const { site } = useCommonData();
  const { statusText, statusColor, nextNormalOpeningoursStatus } = useCurrentOpeningHoursStatus(
    new OpeningHoursHelper(site, library)
  );

  return (
    <Stack spacing={0} role="status" {...chakraProps}>
      <Flex alignItems="center" gap=".5rem">
        <Box
          backgroundColor={statusColor}
          fontSize=".75em"
          flexShrink={0}
          height="1em"
          width="1em"
          borderRadius="50%"
        />
        <Text {...capitalizeFirstLetter}>{statusText}</Text>
      </Flex>
      {nextNormalOpeningoursStatus && <Text fontSize="xs">{nextNormalOpeningoursStatus}</Text>}
    </Stack>
  );
}
