import { OpeningHoursDay, SelfServiceOpeningHoursDay } from "@libry-content/types";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { Translate, useTranslation } from "../../../utils/hooks/useTranslation";
import { DateHelper } from "@libry-content/common";
import { OpeningHoursHelper } from "./openingHoursHelper";

export const dayIsOpen = (day: Pick<OpeningHoursDay, "closed" | "spans"> | undefined) =>
  !day?.closed && !!day?.spans?.length;

export const selfServiceDayIsOpen = (day: Pick<SelfServiceOpeningHoursDay, "enabled" | "spans"> | undefined) =>
  !!day?.enabled && !!day?.spans?.length;

export const useNearestOpenNormalHoursThisWeek = (helper: OpeningHoursHelper) => {
  const translate = useTranslation();
  return nearestOpenNormalHoursThisWeek(translate, helper);
};

export const nearestOpenNormalHoursThisWeek = (translate: Translate, helper: OpeningHoursHelper): string => {
  const { t } = translate;
  const nearestOpensAt = helper
    .sortedWeekOpeningHours(translate)
    .filter((openingHoursDay) => !openingHoursDay.normalHours?.closed)
    .flatMap((openingHourDay) =>
      openingHourDay.normalHours?.spans?.map((span) => ({
        date: DateHelper.fromTimeAndDate(span.opens!, new DateHelper(openingHourDay.date).toNorwegianISODateString())
          .date,
        label: openingHourDay.label,
        opensAt: span.opens,
      }))
    )
    .filter((it) => it && isAfter(it.date, new Date())) // Fjerner åpningstider som allerede er passert
    .sort((a, b) => (isBefore(a?.date!, b?.date!) ? -1 : 1))?.[0]; // Velger neste normale åpningstid

  if (!nearestOpensAt) return t("Stengt denne uka");

  return `${t("Åpner")} ${nearestOpensAt.label.toLowerCase()} ${t("kl.")} ${nearestOpensAt.opensAt}`;
};
