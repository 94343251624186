import { sortByMultiple } from "@biblioteksentralen/utils";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { ResolvedServiceSummary } from "./sanityQuery";

export const useSortedServices = (services?: ResolvedServiceSummary[]) => {
  const { ts } = useTranslation();

  if (!services) return [];

  return sortByMultiple(
    services,
    (service) => ts(service?.title) ?? "N/A",
    (service) => ts(service.serviceType?.label) ?? "N/A"
  );
};
