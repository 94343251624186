import { Box, BoxProps } from "@biblioteksentralen/react";
import { OpeningHoursTimeSpan } from "@libry-content/types";
import { useTranslation } from "../../utils/hooks/useTranslation";

const removeLeading0 = (time?: string) => time?.replace(/^0/, "");

export function TimeSpan({ timespan }: { timespan?: OpeningHoursTimeSpan }) {
  return (
    <Box as="span" whiteSpace="nowrap">
      <Box as="time">{removeLeading0(timespan?.opens)}</Box>
      {" - "}
      <Box as="time">{removeLeading0(timespan?.closes)}</Box>
    </Box>
  );
}

export function TimeSpans({ spans, ...chakraProps }: { spans?: OpeningHoursTimeSpan[] } & BoxProps) {
  if (!spans) return null;

  return (
    <Box
      sx={{
        "> *:not(:first-of-type):before": {
          content: '"&"',
          margin: "0 0.25rem",
        },
      }}
      display="inline-flex"
      justifyContent="flex-end"
      flexWrap="wrap"
      lineHeight={1.2}
      {...chakraProps}
    >
      {spans?.map((span, idx) => (
        <TimeSpan key={`timespan-${idx}`} timespan={span} />
      ))}
    </Box>
  );
}

export const TimespansOrClosed = ({ spans, ...chakraProps }: { spans?: OpeningHoursTimeSpan[] } & BoxProps) => {
  const { t } = useTranslation();

  if (!spans?.length) return <>{t("Stengt")}</>;

  return <TimeSpans spans={spans} {...chakraProps} />;
};

export default TimeSpan;
