import { getSiteUrl, imageUrlBuilder } from "@libry-content/common";
import { DecorativeImage, ImageWithMetadata, Library } from "@libry-content/types";
import type { Library as SchemaOrgLibrary } from "schema-dts";
import { ResolvedSite } from "../layout/commonData";
import { getPath } from "../../utils/getPath";
import { SchemaGetter } from "../../utils/schemaOrg/schemaGetter";
import { useCommonData } from "../layout/CommonDataProvider";
import { OpeningHoursHelper } from "../openingHours/openingHoursHelper/openingHoursHelper";

export const sortLibraries = <T extends Pick<Library, "_id">>(site: ResolvedSite, libraries: T[] = []) => {
  const getSortIndex = ({ _id }: T) => site.libraryOrdering?.findIndex(({ _ref }) => _ref === _id) ?? 0;

  return [...libraries].sort((libraryA, libraryB) => getSortIndex(libraryA) - getSortIndex(libraryB));
};

export const useSortedLibraries = <T extends Pick<Library, "_id">>(libraries: T[] = []) => {
  const { site } = useCommonData();
  if (!site) {
    console.error("Could not find site when sorting libraries");
    return libraries;
  }

  return sortLibraries(site, libraries);
};

export const getSchemaOrgImageUrl = (image: ImageWithMetadata | DecorativeImage) =>
  imageUrlBuilder(image) // https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1638963510039100
    ?.ignoreImageParams()
    .size(100, 100)
    .fit("fillmax")
    .bg("0000")
    .format("png")
    .url() ?? "";

export const getSchemaOrgLibrary: SchemaGetter<
  Pick<
    Library,
    "_id" | "_type" | "name" | "openingHours" | "selfServiceOpeningHours" | "contactInfo" | "image" | "teaser"
  >,
  Exclude<SchemaOrgLibrary, string>
> = ({ site, data: library, ts }) => {
  const openingHoursHelper = new OpeningHoursHelper(site, library);
  const visitingAddress = library.contactInfo?.visitingAddress;

  return {
    "@type": "Library",
    name: ts(library.name),
    description: ts(library.teaser),
    url: `${getSiteUrl(site)}${getPath(library)}`,
    openingHoursSpecification: openingHoursHelper.schemaOrgOpeningHours,
    specialOpeningHoursSpecification: openingHoursHelper.schemaOrgSpecialOpeningHours,
    telephone: library.contactInfo?.phone,
    email: library.contactInfo?.email,
    address: visitingAddress && {
      "@type": "PostalAddress",
      streetAddress: visitingAddress?.streetAddress,
      postalCode: visitingAddress?.postalCode,
      addressLocality: visitingAddress?.city,
    },
    ...(library.image?.asset ? { image: getSchemaOrgImageUrl(library.image) } : {}),
  } satisfies SchemaOrgLibrary;
};
